<template>
  <AboutPage></AboutPage>
  <Footer></Footer>
</template>

<script>
// @ is an alias to /src
import AboutPage from "@/components/AboutPage.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "About",
  components: {
    AboutPage,
    Footer
  }
};
</script>

<style scoped></style>
