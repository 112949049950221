<template>
  <div class="container-about">
    <p class="description-about">
      Fixated started as an Instagram page in 2017 from a small group of friends
      riding Fixedgear-freestyle in The Netherlands. It grew bigger over the
      years and in 2021 we made the step to become a brand.
      <br /><br />
      Never miss our updates:
    </p>
    <div class="link-about flex-container">
      <p>
        <a
          href="https://www.youtube.com/channel/UC7wtQRWnda3pRwMwBj2LGfQ"
          class="social flex-item"
          >Youtube</a
        >
      </p>

      <p>
        <a
          href="https://www.instagram.com/fixated.fgfs/"
          class="social flex-item"
          >Instagram</a
        >
      </p>
    </div>
    <div
      class="slideshow"
      v-bind:style="{ backgroundImage: changeBackgroundImage() }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",

  data() {
    return {
      images: [
        {
          img: require("../assets/background/1.jpg")
        },
        {
          img: require("../assets/background/2.jpg")
        },
        {
          img: require("../assets/background/3.jpg")
        },
        {
          img: require("../assets/background/4.jpg")
        },
        {
          img: require("../assets/background/5.jpg")
        }
      ],
      item: ""
    };
  },
  methods: {
    changeBackgroundImage() {
      var item = this.images[Math.floor(Math.random() * this.images.length)]
        .img;
      //test 1:
      return "url(" + item + ")";
    }
  },
  created: function() {
    this.changeBackgroundImage();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-about {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  text-align: center;
  overflow-x: hidden;
}

.description-about {
  width: 80%;
  display: block;
  font-size: 4vw;
  line-height: 4.5vw;
  /* background-color: #eee; */
  margin-left: auto;
  margin-right: auto;
  padding-top: 2vh;
}

.social {
  color: #f57e20;
  padding: 10px;
  font-size: 4vw;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0vh;
  margin: 0 auto;
  margin-top: 0vh;
  list-style: none;
  width: auto;
}

.flex-item {
  width: 100vw;
  height: auto;
  margin-bottom: 1vh;
  background-color: #171717;
  margin-bottom: 2vh;
  margin: 0 auto;
}

.slideshow {
  width: 100vw;
  height: 59vh;
  margin: 0 auto;
  align-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-content: center;
}

/* desktop and ipad pro*/
@media (min-width: 768px) {
  .container-about {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .description-about {
    width: 70%;
    display: block;
    font-size: 2vw;
    line-height: 2.5vw;
    /* background-color: #eee; */
    margin-left: auto;
    margin-right: auto;
  }
  .slideshow {
    width: 100vw;
    height: 100vh;
    margin: 0;
    margin-left: 0vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .social {
    color: #f57e20;
    padding: 10px;
    font-size: 2vw;
  }
}

/* mobile */
@media (max-width: 768px) {
  .flex-container {
    width: 100%;
  }

  .slideshow {
    width: 92vw;
    max-width: 92vw;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .description-about {
    font-size: 1em;
    line-height: 1em;
  }

  .social {
    font-size: 1em;
  }
}
</style>
